import { defineComponent, reactive } from 'vue';
export default defineComponent({
    name: 'SecurityUpdatePolicy',
    setup() {
        const state = reactive({
            header: ['Product', 'Device where EMS is located', 'Discontinuation dates', 'Security Update Support'],
            data: [
                {
                    product: 'Q.HOME CORE',
                    device: 'Q.VOLT',
                    discontinuationDate: '(Currently in production)',
                    securityUpdateSupport: '(Not determined)',
                },
                {
                    product: 'Q.HOME G4',
                    device: 'Q.HOME HUB',
                    discontinuationDate: '(Preparing for sale)',
                    securityUpdateSupport: '(Not determined)',
                },
            ],
        });
        return {
            state,
        };
    },
});
